import { Component, inject, signal } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@atoms/button/button.component';
import { UploadService } from '@core/services/files/upload.service';
import { ToastStore } from '@core/store/toast/toast.state';
import { FileUploadModule } from 'primeng/fileupload';
import { firstValueFrom, forkJoin } from 'rxjs';

@Component({
  selector: 'app-upload-photo',
  standalone: true,
  imports: [FileUploadModule, ButtonComponent, CommonModule],
  templateUrl: './upload-photo.component.html',
  styleUrl: './upload-photo.component.scss',
})
export class UploadPhotoComponent {
  public isPhotoUploaded = signal(false);

  public photoUploadUrl = signal<any>({});

  private uploadService = inject(UploadService);

  private toastStore = inject(ToastStore);
  uploadedFiles = signal<any[]>([]);

  choose(event: any, callback: any) {
    callback();
  }

  onTemplatedUpload() {
    const forkFiles = firstValueFrom(
      forkJoin(
        this.uploadedFiles().map((file) =>
          this.uploadService.uploadDocument(file.file)
        )
      )
    );

    forkFiles
      .then((res) => {
        console.log({ res });

        this.toastStore.showToast({
          severity: 'success',
          summary: 'Exito',
          detail: 'Se subieron los archivos correctamente',
        });
        const filesUrl: string[] = res.map((file) => file.data);
        console.log(filesUrl);
        const pdfIndex = this.uploadedFiles().findIndex(
          (file) => file.mimeType === 'application/pdf'
        );
        console.log({ pdfIndex });
        if (pdfIndex !== -1) {
          this.photoUploadUrl.set({
            pdf: filesUrl[pdfIndex] ?? '',
          });
        }

        const xmlIndex = this.uploadedFiles().findIndex(
          (file) => file.mimeType === 'text/xml'
        );
        console.log({ xmlIndex });
        if (xmlIndex !== -1) {
          this.photoUploadUrl.set({
            ...this.photoUploadUrl(),
            xml: filesUrl[xmlIndex] ?? '',
          });
        }

        this.isPhotoUploaded.set(true);

        console.log(this.photoUploadUrl());
      })
      .catch(() => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Error uploading file',
        });
      });
  }

  onSelectedFiles(event: any) {
    console.log(event.files[0]);
    for (const file of event.files) {
      const name = file.name;
      const mimeType = file.type;
      const fileExists = this.uploadedFiles().find((f) => f.name === name);
      if (!fileExists) {
        this.uploadedFiles.set([
          ...this.uploadedFiles(),
          { name, mimeType, file },
        ]);
      }
    }
    console.log(this.uploadedFiles());
  }

  onRemoveTemplatingFile(
    event: any,
    file: any,
    removeFileCallback: any,
    index: any
  ) {
    console.log({ event, file, removeFileCallback, index });

    const files = this.uploadedFiles().filter((f) => f.name !== file.name);

    this.uploadedFiles.set(files);
    removeFileCallback(event, index);
  }
}
