<app-layout title="Detalles del cliente" backLink="/clients">
  <app-button
    header
    (onClick)="onAddPayment()"
    [text]="true"
    type="button"
    label="Agregar pago"
  />
  <app-client-info
    (onEditPayment)="onEditPayment($event)"
    (onAddPayment)="onAddPaymentDetail($event)"
    (onDeletePayment)="onDeletePayment($event)"
    (onRefreshTable)="getClientDetails(clientDetails().id)"
    [clientDetails]="clientDetails()"
    [loading]="loading()"
  ></app-client-info>
</app-layout>
