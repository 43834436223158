<form class="flex flex-column lg:flex-row lg:flex-wrap">
  <app-input
    class="col-12 lg:col-6"
    title="Concepto"
    placeholder="A1234"
    type="text"
    [control]="paymentsForm.controls.invoiceNumber"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Total"
    placeholder="0.00"
    type="number"
    [control]="paymentsForm.controls.total"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Fecha pago"
    type="date"
    placeholder="dd/mm/yyyy"
    [control]="paymentsForm.controls.invoiceDate"
  />

  <div class="col-12 flex justify-content-start gap-3 align-items-center">
    @if(paymentsForm.value.pdfUrl){
    <a class="text-blue-300 hover:text-blue-500" href="{{ paymentsForm.value.pdfUrl }}" target="_blank">
      Ver PDF
    </a>
    } @if(paymentsForm.value.xmlUrl){
    <a class="text-blue-300 hover:text-blue-500"  href="{{ paymentsForm.value.xmlUrl }}" target="_blank">
      Ver XML
    </a>
    }
  </div>

  <app-upload-photo #uploadPhoto class="col-12 w-full"></app-upload-photo>

  <app-button
    class="col-12"
    type="button"
    [disabled]="!paymentsForm.valid"
    (onClick)="onSubmit()"
    label="Guardar"
    [raised]="true"
    [text]="true"
  />
</form>
