<form class="flex flex-column lg:flex-row lg:flex-wrap">
  <app-input
    #name
    class="col-12 lg:col-6"
    title="Nombre"
    placeholder="MO REFACCIONES"
    type="text"
    [control]="patientsForm.controls.name"
    [helpText]="patientsForm.controls.name.value?.length + ' / 30'"
  />

  <app-input
    class="col-12 lg:col-6"
    title="RFC"
    placeholder="MOR123456789"
    type="text"
    [control]="patientsForm.controls.rfc"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Teléfono"
    placeholder="1234567890"
    type="text"
    [control]="patientsForm.controls.phoneNumber" 
    [helpText]="patientsForm.controls.phoneNumber.value?.length + ' / 10'"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Correo"
    placeholder="mo.reffaciones@gmail.com"
    type="text"
    [control]="patientsForm.controls.email"
  />

  <app-button
    class="col-12"
    type="button"
    [disabled]="!patientsForm.valid"
    (onClick)="onSubmit()"
    label="Guardar"
    [raised]="true"
    [text]="true"
  />
</form>
