import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@atoms/button/button.component';
import { DropdownComponent } from '@atoms/dropdown/dropdown.component';
import { InputComponent } from '@atoms/input/input.component';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-clients-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputComponent,
    DropdownComponent,
    ButtonComponent
  ],
  templateUrl: './clients-form.component.html',
  styleUrl: './clients-form.component.scss',
})
export class ClientsFormComponent {
  public patientsForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    email: new FormControl(''),
    rfc: new FormControl('', [Validators.maxLength(13) , Validators.minLength(11)] ),
  });

  public ref = inject(DynamicDialogRef);

  private instance: DynamicDialogComponent;

  private dialogService = inject(DialogService);

  constructor() {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      const { data }: { data: any } = this.instance;
      this.patientsForm.patchValue({
        id: data.id,
        name: data.name,
        phoneNumber: data.phoneNumber,
        email: data.email,
        rfc: data.rfc,
      });
    }
  }

  public onSubmit() {
    this.ref.close(this.patientsForm.value);
  }
}
