<div
class="flex justify-content-center align-items-center"
  [class]="
    data
      ? 'text-green-400'
      : 'text-red-400'
  "
>
  <i
    style="font-size: 1.4rem"
    [class]="data ? 'pi pi-check-circle' : 'pi pi-times-circle'"
  ></i>
</div>
