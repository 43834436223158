<app-table
  [data]="payments()"
  [loading]="loading()"
  [headers]="headers()"
  [childrenHeaders]="childrenHeaders()"
  [childrenField]="'paymentDetail'"
  [showTableActions]="false"
  [isExpandable]="true"
>
</app-table>
