<p-fileUpload
  #files
  class="w-full"
  (onSelect)="onSelectedFiles($event)"
  [multiple]="true"
  accept="application/pdf, text/xml"
  maxFileSize="1000000"
>
  <ng-template
    pTemplate="header"
    let-files
    let-chooseCallback="chooseCallback"
    let-clearCallback="clearCallback"
    let-removeFileCallback="removeFileCallback"
    let-uploadCallback="uploadCallback"
  >
    <div
      class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2"
    >
      <div class="flex gap-2">
        <p-button
          (onClick)="choose($event, chooseCallback)"
          icon="pi pi-images"
          [disabled]="uploadedFiles().length == 2"
          [rounded]="true"
          [outlined]="true"
        />
        <p-button
          (onClick)="onTemplatedUpload()"
          icon="pi pi-cloud-upload"
          [rounded]="true"
          [outlined]="true"
          severity="success"
          [disabled]="!files || files.length === 0 || isPhotoUploaded()"
        />
      </div>
    </div>
  </ng-template>
  <ng-template
    pTemplate="content"
    let-files
    let-uploadedFiles="uploadedFiles"
    let-removeFileCallback="removeFileCallback"
    let-removeUploadedFileCallback="removeUploadedFileCallback"
  >
    <div class="flex flex-column" *ngIf="files?.length > 0">
        <div
          *ngFor="let file of files; let i = index"
          class="card h-5rem m-0 px-6 flex justify-content-between align-items-center gap-3"
        >
          <span class="font-semibold">{{ file.name }}</span>
          <p-button
            icon="pi pi-times"
            (onClick)="
              onRemoveTemplatingFile($event, file, removeFileCallback, i)
            "
            [outlined]="true"
            [rounded]="true"
            severity="danger"
          />
        </div>
      </div>
  </ng-template>
  <ng-template pTemplate="file"> </ng-template>
  <ng-template pTemplate="empty">
    <div
      class="flex align-items-center justify-content-center flex-column h-50rem"
    >
      <i
        class="pi pi-cloud-upload border-2 border-circle p-2 text-8xl text-200 border-200"
      ></i>
      <p class="mt-4 mb-0">Arrasta y suelta los archivos aqui.</p>
    </div>
  </ng-template>
</p-fileUpload>
