import { Component, OnInit, inject, viewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@atoms/button/button.component';
import { CalendarComponent } from '@atoms/calendar/calendar.component';
import { DropdownComponent } from '@atoms/dropdown/dropdown.component';
import { InputComponent } from '@atoms/input/input.component';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { UploadPhotoComponent } from '../upload-photo/upload-photo.component';

@Component({
  selector: 'app-payments-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    DropdownComponent,
    CalendarComponent,
    UploadPhotoComponent,
  ],
  templateUrl: './payments-form.component.html',
  styleUrl: './payments-form.component.scss',
})
export class PaymentsFormComponent implements OnInit {
  public paymentsForm = new FormGroup({
    id: new FormControl(''),
    total: new FormControl('', [Validators.required, Validators.min(1)]),
    subTotal: new FormControl(0),
    invoiceDate: new FormControl(new Date()),
    invoiceNumber: new FormControl(''),
    pdfUrl: new FormControl(''),
    xmlUrl: new FormControl(''),
    paid: new FormControl(0),
  });

  public ref = inject(DynamicDialogRef);
  private instance: DynamicDialogComponent;

  private dialogService = inject(DialogService);

  public uploadDocumentComponent =
    viewChild.required<UploadPhotoComponent>('uploadPhoto');

  constructor() {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      const { data }: { data: any } = this.instance;
      console.log({ data });
      this.paymentsForm.patchValue({
        id: data.id,
        invoiceNumber: data.invoiceNumber,
        total: data.total,
        subTotal: data.subTotal,
        pdfUrl: data.pdfUrl,
        xmlUrl: data.xmlUrl,
        invoiceDate: new Date(data.invoiceDate),
        paid: data.paid,
      });
    }
  }

  public onSubmit() {
    console.log(this.paymentsForm.value);

    const urls = this.uploadDocumentComponent().photoUploadUrl();

    if (urls.pdf !== '') {
      this.paymentsForm.patchValue({
        pdfUrl: urls.pdf,
      });
    }

    if (urls.xml !== '') {
      this.paymentsForm.patchValue({
        xmlUrl: urls.xml,
      });
    }

    this.ref.close(this.paymentsForm.value);
  }
}
