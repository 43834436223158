import { Component, HostListener, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from '@atoms/loader/loader.component';
import { AuthStore } from '@core/store/auth/auth.state';
import { LoaderStore } from '@core/store/loader/loader.state';
import { ButtonModule } from 'primeng/button';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ButtonModule, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'AXZY_MO_UI';

  private authStore = inject(AuthStore);

  public loaderStore = inject(LoaderStore);

  @HostListener('window:beforeunload', ['$event'])
  onTabClose($event: BeforeUnloadEvent) {
    localStorage.setItem('authStore', JSON.stringify(this.authStore.auth()));
  }
}
