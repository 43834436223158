import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { TResult } from '@core/models/TResult';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private URL_BASE = `${environment.apiUrl}/${environment.apiVersion}/files`;

  constructor(private http: HttpClient) {}

  uploadDocument(file: File): Promise<TResult<string>> {
    const formData = new FormData();
    formData.append('file', file);

    return firstValueFrom(
      this.http.post<TResult<string>>(this.URL_BASE, formData)
    );
  }
}
